// let response = await axios.get(`${config.api}/api/Provider`);
import axios from "axios";
import { configUrl } from "config.js";

const getAllProviders = async (page, pageSize, providerTypeId, providerId) => {
  let Response = await axios.get(
    providerId == null
      ? `${configUrl.AssessmentServer}/api/Provider?pageNumber=${page}&pageSize=${pageSize}&providerTypeId=${providerTypeId}`
      : `${configUrl.AssessmentServer}/api/Provider?pageNumber=${page}&pageSize=${pageSize}&providerTypeId=${providerTypeId}&parentProviderId=${providerId}`
  );
  return Response.data;
};
export default {
  getAllProviders: getAllProviders,
};
