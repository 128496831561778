import axios from "axios";
import { configUrl } from "config.js";

const getAllCourses = async (
  page,
  pageSize,
  contentFilter,
  stringContentProviderIds
) => {
  let ContentFilter = contentFilter ? parseInt(contentFilter) : 0;

  console.log(stringContentProviderIds, "clgs1");

  let coursesResponse = await axios.get(
    stringContentProviderIds == null
      ? `${configUrl.AssessmentServer}/api/Courses?pageNumber=${page}&pageSize=${pageSize}&contentFilter=${ContentFilter}`
      : `${configUrl.AssessmentServer}/api/Courses?pageNumber=${page}&pageSize=${pageSize}&contentFilter=${ContentFilter}&contentCuratorIds=${stringContentProviderIds}`
  );
  return coursesResponse.data["$values"];
};
export default {
  getAllCourses: getAllCourses,
};
