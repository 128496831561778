import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import React, { useContext, useEffect, useState } from "react";

import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { CircularProgress } from "@material-ui/core";
import { AssignmentIndRounded } from "@material-ui/icons";
import CustomAccordion from "components/Accordion/Accordion";
import CustomAlert from "components/CustomAlert/CustomAlert";
import { LoginContext } from "contexts/LoginContext";
import useGetAvailableUserForCourse from "hooks/courseTestMappingHooks/useGetAvailableUserForCourse";
import useGetCourseUserMappingByCourseId from "hooks/courseTestMappingHooks/useGetCourseUserMappingByCourseId";
import useUserMapToCourse from "hooks/courseTestMappingHooks/useUserMapToCourse";
import CourseUserMappedGrid from "views/Components/CourseUserMappedGrid";

function CourseUserMappingGrid(props) {
  const { state, procureCount } = props;
  console.log("state", state);
  const { data: users, isLoading, refetch } = useGetAvailableUserForCourse(
    state.courseId
  );
  const mapTest = useUserMapToCourse(state.courseId);
  const mappedUsers = useGetCourseUserMappingByCourseId(state.courseId);
  console.log(mappedUsers, "mappedUsers1");

  const { user } = useContext(LoginContext);
  const [procureCounts, setProcureCounts] = useState(procureCount);
  const [cols, setCols] = useState([]);
  const [select, setSelection] = useState([]);
  const [showAlert, setShowAlert] = useState(null);
  const handleRowSelection = (e) => {
    setSelection(e);
  };

  React.useEffect(() => {
    let columns = [
      {
        field: "userId",
        headerName: "Id",
        editable: false,
        width: 100,
        hide: true,
      },
      {
        field: "fullName",
        headerName: "Full Name",
        editable: false,
        width: 200,
      },
      {
        field: "userName",
        headerName: "User Name",
        editable: false,
        width: 200,
      },
      {
        field: "mobile",
        headerName: "Mobile No",
        editable: false,
        width: 200,
      },
    ];

    setCols(columns);
  }, []);

  const mapToTest = async () => {
    console.log(select, "selected Students");
    if (
      procureCount == null ||
      (procureCounts >= select.length &&
        procureCounts - select.length <= procureCounts)
    ) {
      let payload = select.map((x) => {
        return {
          courseId: state.courseId,
          userId: x,
          createdBy: user,
          createdDate: new Date(),
          updatedBy: user,
          updatedDate: new Date(),
        };
      });

      mapTest.mutate(payload);
    } else {
      setShowAlert(
        <CustomAlert
          open={true}
          severity="warning"
          alertMessage="Need to upgrade License for assign students"
        />
      );
      setTimeout(() => {
        setShowAlert(null);
      }, 3000);
      return;
    }
  };
  useEffect(() => {
    if (mapTest.isSuccess) {
      setProcureCounts(procureCounts - select.length);
    }
  }, [mapTest.isSuccess]);

  useEffect(() => {
    if (mapTest.isError) {
      setShowAlert(
        <CustomAlert
          open={true}
          severity="error"
          alertMessage="Need to upgrade License for assign students"
        />
      );
      setTimeout(() => {
        setShowAlert(null);
      }, 4000);
      return;
    }
  }, [mapTest.isError]);

  useEffect(() => {
    if (mappedUsers.isSuccess) {
      console.log(procureCount - mappedUsers?.data?.length, "proccnt");
      setProcureCounts(procureCount - mappedUsers?.data?.length);
    }
  }, [mappedUsers]);

  console.log(procureCounts, "ProcCounts");
  const addProcureCount = () => {
    setProcureCounts(procureCounts + 1);
  };
  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <AssignmentIndRounded />
        </CardIcon>
      </CardHeader>
      <CardBody>
        <CustomAccordion
          active={1}
          collapses={[
            {
              title: <h3>Assigned Student List</h3>,
              content: state.courseId ? (
                <CourseUserMappedGrid
                  status={state?.scheduleStatus}
                  courseId={state?.courseId}
                  procureCounts={procureCounts}
                  setProcureCounts={() => {
                    addProcureCount();
                  }}
                />
              ) : (
                ""
              ),
            },
            {
              title: <h3>Available Student List</h3>,
              content: (
                <>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        color="primary"
                        onClick={mapToTest}
                        disabled={mapTest.isLoading}
                      >
                        {mapTest.isLoading ? "Assigning .." : "Assign Student"}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {users && cols.length > 0 && isLoading == false ? (
                        <div style={{ height: 500, width: "100%" }}>
                          <div style={{ display: "flex", height: "90%" }}>
                            <div style={{ flexGrow: 1 }}>
                              <CustomDataGrid
                                columns={cols}
                                rows={users}
                                autoPageSize={true}
                                checkboxSelection
                                onSelectionModelChange={handleRowSelection}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <CircularProgress />
                      )}
                    </GridItem>
                  </GridContainer>
                  {mapTest.isSuccess && (
                    <CustomAlert
                      open={true}
                      severity="success"
                      alertMessage="Users Mapped to Test"
                    />
                  )}
                  {showAlert}
                </>
              ),
            },
          ]}
        />
      </CardBody>
    </Card>
  );
}

export default CourseUserMappingGrid;
