import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@mui/material";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import { LoginContext } from "contexts/LoginContext";
import React, { useContext, useRef } from "react";
// import { DataGridPro } from '@material-ui/data-grid';
// import { GridFilterModel, DataGridPro } from '@material-ui/data-grid';
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import GridItem from "components/Grid/GridItem";
import useGetAllCity from "hooks/configurationsHooks/useGetAllCity";
import useGetAllClass from "hooks/configurationsHooks/useGetAllClass";
import useGetAllEduBoard from "hooks/configurationsHooks/useGetAllEduBoard";
import useGetAllProfession from "hooks/configurationsHooks/useGetAllProfession";
import useGetAllState from "hooks/configurationsHooks/useGetAllState";
import getAllStudents from "services/ApplicationUser/getAllStudents";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Radio from "@mui/material/Radio";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import useCloneUsers from "hooks/userHooks/useCloneUsers";
import "katex/dist/katex.min.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    ...sweetAlertStyle,
    tooltip: {
        fontSize: "1em",
        backgroundColor: "white",
    },
}));

export default function CloneUsersListing({ selected, onClose }) {
    //console.log(props, "props321");
    React.useEffect(() => {
        setTestPageState({ pageSize: 10, page: 1 });
        setCoursePageState({ pageSize: 10, page: 1 });
    }, []);


    const { user, userPrivileges, setTestPageState, setQuestionPageState, setCoursePageState } = useContext(LoginContext);
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    console.log(rows)
    const [state, setStats] = React.useState([]);
    const [citys, setCitys] = React.useState([]);
    const [Courses, setCourses] = React.useState([])
    const [language, setLanguage] = React.useState([]);
    const [educationbord, setEducattionBord] = React.useState([]);
    const [clas, setClas] = React.useState([]);
    const [professions, setProfessions] = React.useState([]);
    const [alleducation, setAllEducation] = React.useState([]);
    const [stateValueInt, setStateValueInt] = React.useState('');
    const [pendingStudents, setPendingStudents] = React.useState(true);
    const [studentFilter, setStudentFilter] = React.useState('1')
    const [select, setSelection] = React.useState([]);
    const [isLoadingExport, setIsLoadingExport] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(null)
    const [flag, setFlag] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(0);
    const [alertMessage, setAlertMessage] = React.useState(null);
    const [severity, setSeverity] = React.useState("success");
    const [alert, setAlert] = React.useState(null);

    const [pageState, setPageState] = React.useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    })

    const cloneUsersCall = useCloneUsers();

    React.useEffect(() => {
        const fetchData = async () => {
            setPageState(old => ({ ...old, isLoading: true }))
            console.log("it is", studentFilter, studentFilter == 1 ? false : true);
            let filterStudents = studentFilter == 1 ? true : false;
            let getStudents = await getAllStudents.getAllStudents(pageState.page, pageState.pageSize, filterStudents);
            console.log(getStudents, 'resp222')
            let Students = getStudents["$values"].map((x, i) => { return { ...x, id: x.userId }; })

            setPageState(old => ({ ...old, isLoading: false, data: Students, total: Students.length > 0 ? Students[0].totalCount : 0 }))
        }
        if (pageState.page > 0) {
            fetchData();
        }
    }, [pageState.page, pageState.pageSize, studentFilter])

    console.log(stateValueInt)
    // const [st,setSt]=React.useState('');
    const ref = useRef(null);
    const classes = useStyles();
    const { data: statedata } = useGetAllState("state", true);
    const { data: getcity } = useGetAllCity("city", true)
    console.log(getcity)
    const { data: geteduboard } = useGetAllEduBoard("EduBoard", true)
    const { data: getclass } = useGetAllClass("class", true)
    const { data: getprofession } = useGetAllProfession("profession", true)
    console.log(getprofession)

    const isPrivilege = (x) => {
        if (userPrivileges?.Privileges?.Student.indexOf(x) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }
    React.useEffect(() => {
        setTestPageState({ pageSize: 10, page: 1 });
        setQuestionPageState({ pageSize: 10, page: 1 });
        setCoursePageState({ pageSize: 10, page: 1 })
    }, [])



    React.useEffect(() => {
        if (geteduboard) {
            setEducattionBord(
                geteduboard.filter(o => o.isActive)
                    .map((x, i) => {
                        // console.log(x)
                        return { ...x, name: x.valueString, id: x.valueInt, value: x.valueString };
                    })
            );
        }
    }, [geteduboard]);
    let ed = educationbord.filter(o => o.isDefault)
    console.log(ed)
    React.useEffect(() => {
        if (getclass) {
            setClas(
                getclass.filter(o => o.isActive)
                    .map((x, i) => {
                        // console.log(x)
                        return { ...x, name: x.valueString, id: x.valueInt, value: x.valueString };
                    })
            );
        }
    }, [getclass]);






    React.useEffect(() => {
        let cols = [
            {
                field: "select",
                headerName: "",
                width: 60,
                renderCell: (cellValues) => {
                    return (
                        <Radio
                            checked={selectedRow === cellValues.row.userId}
                            //onChange={() => handleRowSelection(cellValues.row.userId)} 
                            onChange={() => setSelectedRow(cellValues.row.userId)}
                            value={selectedRow}
                        />
                    );
                },
            },
            {
                field: "fullName",
                headerName: "Full Name",
                editable: false,
                width: 240,
                renderCell: (cellValues) => {
                    console.log(cellValues, 'cells')
                    return (
                        <Tooltip classes={{ tooltip: classes.tooltip }} title={cellValues.row.fullName ?? 'none'}>
                            <span>
                                {cellValues.row.fullName ?
                                    cellValues.row.fullName?.substr(0, 22) : 'none'
                                }
                            </span>
                        </Tooltip>
                    )
                }
            },
            {
                field: "class",
                headerName: "Class",
                editable: false,
                width: 130,
            },
            {
                field: "board",
                headerName: "Board",
                editable: false,
                width: 130,
            },
            {
                field: "email",
                headerName: "Email",
                editable: false,
                width: 250,

            },
            {
                field: "mobile",
                headerName: "Mobile No",
                editable: false,
                width: 160,
            },



        ];

        setColumns(cols);
    }, [studentFilter, selectedRow]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };

    const handleRowSelection = (selectedRowId) => {
        console.log("selected row ", selectedRowId, selectedRow);
        setSelectedRow(selectedRowId); // Update the selected row
    };
    console.log("selected row1 ", selectedRow);

    // const handleRowSelection = (e) => {
    //     console.log("selcting", e);
    //     setSelection(e);
    // };

    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };

    React.useEffect(() => {
        console.log(cloneUsersCall, "clone check");
        if (cloneUsersCall.isSuccess == true) {
            showSnackBar("Data Clone successfully.");
            setAlert(null);
            setTimeout(() => {
                onClose();
            }, 2000);
        }
    }, [cloneUsersCall.isSuccess]);

    React.useEffect(() => {
        console.log(cloneUsersCall, "cloning eror");
        if (cloneUsersCall.isError == true) {
            showSnackBar(cloneUsersCall?.error?.response?.data || "Error cloning data.", "error");
            setAlert(null);
            setTimeout(() => {
                onClose();
            }, 2000);
        }
    }, [cloneUsersCall.isError]);

    const cloneSubmit = () => {
        console.log("findinf same data", selected, selectedRow, selected?.includes((selectedRow)));
        if (selected?.includes(selectedRow) == true) {
            showSnackBar("Cannot clone the same user.", "warning");
            setAlert(null);
            return;
        }
        console.log("sending data", selected, selectedRow);
        const obj = {};
        obj.selected = selected;
        obj.selectedRow = selectedRow;
        cloneUsersCall.mutate(obj);
    }

    return (
        <Card>
            {alert}
            {/* <CardHeader color="rose" icon>
                <CardIcon color="rose">
                    <PersonAddIcon />
                </CardIcon>
            </CardHeader> */}
            <CardBody>
                {/* {props && props.hideHeader == true ? null : null}

        {deleteQuestion.isLoading && (
          <h4 style={{ color: "warning" }}>Deleting question...</h4>
        )} */}
                <GridContainer justifyContent="flex-end" alignItems="flex-end">
                    <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10, marginRight: 10 }}>
                        <Button
                            color="primary"
                            onClick={cloneSubmit}
                            style={{ marginTop: 4, textTransform: "none" }}
                            disabled={!selectedRow || cloneUsersCall?.isLoading}
                        >
                            {cloneUsersCall?.isLoading ? "Cloning..." : "Clone"}
                        </Button>
                    </GridItem>
                </GridContainer>

                <div style={{ height: 650, width: "100%" }}>
                    <div style={{ display: "flex", height: "100%" }}>
                        {pageState.isLoading == false && pageState.data ? (
                            <div ref={ref} style={{ flexGrow: 1, }}>
                                {
                                    <CustomDataGrid         //DataGrid
                                        rows={pageState.data}
                                        rowCount={pageState.total}
                                        loading={pageState.isLoading}
                                        rowsPerPageOptions={[10, 30, 50, 70, 100]}
                                        page={pageState.page - 1}
                                        pageSize={pageState.pageSize}
                                        paginationMode="server"
                                        onPageChange={(newPage) => setPageState(old => ({ ...old, page: newPage + 1 }))}
                                        onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                                        columns={columns}
                                    //onSelectionModelChange={handleRowSelection}
                                    // isRowSelectable={(params: GridRowParams) => params.row.providerId == params.row.providerId ? true : false}    //not validated for select student
                                    //checkboxSelection
                                    //getRowClassName={getRowClassName}
                                    />
                                }
                            </div>
                        ) : (
                            <div style={{ flexGrow: 1 }}>
                                <CustomDataGrid
                                    rows={[]}
                                    loading={pageState.isLoading}
                                    columns={columns}
                                //checkboxSelection
                                />
                            </div>
                        )}
                    </div>
                </div>
            </CardBody>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Card>
    );
}
