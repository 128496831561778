import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomTree from "components/CustomTree/CustomTree";
import { configUrl } from "config";
import { LoginContext } from "contexts/LoginContext";
import { useContext, useEffect, useState } from "react";
import aggrigatorTransactionsService from "services/AggrigatorService/aggrigatorTransactionsService";


const AggregatorTransactionsListing = (props) => {

    const { user, providerId } = useContext(LoginContext);
    const [selectedSeries, setSelectedSeries] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState(null);

    const fetchExistingMappings = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${configUrl.AssessmentServer}/api/ProviderResourceMapping/getAggregatorTransactions/${providerId}`
            );
            const mappings = response.data || [];

            setSelectedSeries(mappings);
        } catch (error) {
            console.error("Error fetching provider resource mapping:", error);
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     fetchExistingMappings();
    // }, [providerId]);

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const response = await aggrigatorTransactionsService.aggrigatorTransactionsService(providerId);
            setCategories(response);
        } catch (error) {
            console.error("Error fetching categories:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (providerId) {
            fetchCategories();
        }
    }, []);


    const columns = [
        { field: "providerName", headerName: "Content Partner", width: 200 },
        { field: "productName", headerName: "Test Series", width: 300 },
        { field: "studentsCount", headerName: "Licenses (Students Count)", width: 200 },
        { field: "createdDate", headerName: "Created Date", width: 300 },
        { field: "createdBy", headerName: "Created By", width: 200 },

    ];

    console.log("resp data", selectedSeries);
    const handleCategoryCheck = (result) => {
        console.log("categoryPresent", result);
    };
    console.log(categories, 'catData')

    return (
        <Card style={{ height: 1500, overflowY: "auto" }}>
            <CardBody>
                {
                    categories?.length == 0 || categories == null ?
                        <div style={{ height: 345, width: "100%", textAlign: 'center' }}>
                            {loading ?
                                <>
                                    <CircularProgress color="secondary"
                                        style={{ size: "1.5rem" }} />
                                    <h4>Loading...</h4>
                                </>
                                :
                                <h4>Data not found</h4>
                            }
                        </div>
                        :
                        <CustomTree
                            rawData={
                                categories ?? null
                            }
                            addCategory={[]}
                            addTopic={[]}
                            refresh={handleCategoryCheck}
                            onCheckCategory={handleCategoryCheck}
                            hideQuestionCount={true}
                        />
                }
            </CardBody>
        </Card>

        // <Card>
        //     <CardHeader color="rose" icon></CardHeader>
        // <CardBody>
        //     <GridContainer>
        //         <GridItem xs={12} sm={12} md={2}>
        //             <CustomSelect label="Select field" options={[]} setvalue={() => { }} />
        //         </GridItem>
        //         <GridItem xs={12} sm={12} md={2}>
        //             <CustomInput
        //                 id="filename"
        //                 formControlProps={{ fullWidth: true }}
        //                 inputProps={{ fullWidth: true, type: "text", autoComplete: "off" }}
        //             />
        //         </GridItem>
        //     </GridContainer>
        //     <div style={{ height: 645, width: "100%" }}>
        //         <CustomDataGrid
        //             rows={selectedSeries}
        //             loading={loading}
        //             columns={columns}
        //             pageSize={pageSize}
        //             rowsPerPageOptions={[10]}
        //         />
        //     </div>
        // </CardBody> 
        // </Card>

    );
};

export default AggregatorTransactionsListing;
