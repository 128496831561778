import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import React, { useContext, useEffect } from "react";

import EditIcon from "@material-ui/icons/Edit";
import Button from "components/CustomButtons/Button.js";

import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import CustomModal from "components/CustomModal/CustomModal";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ScheduleIcon from "@material-ui/icons/Schedule";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CourseScheduleComponent from "./CourseScheduleComponent";
import CourseUserMappingGrid from "./CourseUserMappingGrid";

import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import {
  CloudUploadRounded,
  ListAltOutlined,
  PlayArrowOutlined,
  VisibilitySharp,
  WarningOutlined,
} from "@material-ui/icons";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PublicIcon from "@material-ui/icons/Public";
import MuiAlert from "@material-ui/lab/Alert";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { LoginContext } from "contexts/LoginContext";
import useDeleteCourse from "hooks/courseHooks/useDeleteCourse";
import usePlayCourseTests from "hooks/courseHooks/usePlayCourseTests";
import usePublishCourse from "hooks/courseHooks/usePublishCourse";
import useUploadResourceImage from "hooks/courseHooks/useUploadResourceImage";
import useUserDataCleanup from "hooks/courseHooks/useUserDataCleanup";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router-dom";
import assignCourseTests from "services/QuestionService/assignCourseTests";
import getAllCourses from "services/TestSeriesService/getAllCourses";
import testSeriesScheduleDetailsService from "services/TestSeriesService/testSeriesScheduleDetailsService";
import testSeriesService from "services/TestSeriesService/testSeriesService";
import styles from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import ContentCuratorCourseScheduleComponent from "./ContentCuratorCourseScheduleComponent";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);

function CourseHomeComponent(props) {
  const classes = useStyles();

  // const { isLoading, error, data: courses, refetch } = useGetAllCourses();

  const { user } = useContext(LoginContext);
  const deleteCourse = useDeleteCourse();
  const publishCourse = usePublishCourse();
  const uploadResourceImage = useUploadResourceImage();
  const playCourseTests = usePlayCourseTests();
  console.log('conslog', playCourseTests)
  const cleanupTestUsers = useUserDataCleanup();
  const [courseEditModal, setCourseEditModal] = React.useState(null);

  const [columns, setColumns] = React.useState([]);
  const [select, setSelection] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");
  const [CourseUserMap, setCourseUserMap] = React.useState(null);
  const [CourseSchedule, setCourseSchedule] = React.useState(null);
  const [alert, setAlert] = React.useState(null);
  const [contentFilter, setContentFilter] = React.useState("0");
  const [image, setImage] = React.useState({
    imageData: null,
    courseId: null
  });
  const [resourceImageModal, setResourImageModal] = React.useState(null);
  const [handleClear, setHandleClear] = React.useState(false);



  const {
    providerId,
    userPrivileges,
    licensePackageObject,
    setTestPageState,
    setQuestionPageState,
    coursePageState,
    setCoursePageState,
  } = React.useContext(LoginContext);

  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const fetchData = async (page, pageSize) => {
    setCoursePageState((old) => ({ ...old, isLoading: true }));
    let getCourses = await getAllCourses.getAllCourses(
      page,
      pageSize,
      contentFilter
    );
    setCoursePageState((old) => ({
      ...old,
      isLoading: false,
      data: getCourses,
      total: getCourses?.length > 0 ? getCourses[0].totalCount : 0,
    }));
  };

  React.useEffect(() => {
    if (coursePageState.page > 0) {
      fetchData(coursePageState.page, coursePageState.pageSize);
    }
  }, [
    coursePageState.page,
    coursePageState.pageSize,
    deleteCourse.isSuccess,
    publishCourse.isSuccess,
    contentFilter,
  ]);

  React.useEffect(() => {
    setTestPageState({ pageSize: 10, page: 1 });
    setQuestionPageState({ pageSize: 10, page: 1 });
  }, []);
  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.TestSeries.indexOf(x) !== -1) {
      return true;
    } else {
      return false;
    }
  };
  const reloadGridFunction = (page, pageSize) => {
    fetchData(page, pageSize);
  };


  const uploadImage = (courseIdValue) => {
    image["courseId"] = courseIdValue;
    setImage({ ...image });
    console.log("ad dimage", image, image.imageData);
    uploadResourceImage.mutate(image);

  }

  useEffect(() => {

    if (uploadResourceImage?.isSuccess) {
      setHandleClear(handleClear == true ? false : true);
      hideImageCustomModal();
    }
    if (uploadResourceImage?.isError) {
      setHandleClear(handleClear == true ? false : true);
      hideImageCustomModal();
    }

  }, [uploadResourceImage?.isSuccess, uploadResourceImage?.isError]);

  const hideImageCustomModal = () => {
    setResourImageModal(null);
  };

  const handleChangeLangauage = (e) => {

    image[e.target.name] = e.target.value;
    setImage({ ...image });
  };

  const showCourseEditModal = async (course) => {
    let isCourseDisable = course.providerId == providerId
      ? course.status != 3
        ? course.scheduleStatus != 5
          ? false
          : true
        : true
      : true

    let testSeriesData = await testSeriesService.getTestSeriesData(
      course.courseId
    );
    props.history.push("/admin/course", { state: { ...testSeriesData, isDisabled: isCourseDisable } });

    /*    setTestEditModal(
      <CustomModal
        modal={true}
        setModal={() => hideTestEditModal()}
        content={
          <TestWizard
            state={test}
            hideTitle={true}
            afterFinish={() => {
              hideTestEditModal();
              refetch();
            }}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideTestEditModal()}
        title="Edit Test"
        maxWidth="lg"
        fullWidth={true}
      />
    ); */
  };
  const showAssignedTests = (course) => {
    console.log(course, "course123");
    props.history.push("/admin/testhome", { state: course });
  };
  const removeTestSeries = (id) => {
    deleteCourse.mutate(id);
  };
  const publishTestSeries = (id) => {
    console.log("Loaidng ", publishCourse.isLoading);
    setCoursePageState((old) => ({ ...old, isLoading: true }));
    publishCourse.mutate(id);
    setAlert(null);
  };
  const PlayTestSeries = (id) => {
    console.log("LoaidngPlay ", playCourseTests.isLoading);
    setCoursePageState((old) => ({ ...old, isLoading: true }));
    playCourseTests.mutate(id);
    setAlert(null);
  };

  const cleanupUsers = (id) => {
    console.log("idesCourse", id);
    cleanupTestUsers.mutate(id);
  };
  const showCourseScheduleModal = async (course) => {
    console.log(course?.courseId, "testId");
    let courseScheduleData = await testSeriesScheduleDetailsService.getTestseriesScheduletData(
      course.courseId
    );
    console.log(courseScheduleData, "testDataResponce");
    setCourseSchedule(
      <CustomModal
        modal={true}
        setModal={() => hideCourseScheduleModal()}
        padding1={true}
        content={
          <CourseScheduleComponent
            state={course}
            afterFinish={() => {
              hideCourseScheduleModal();
              // refetch();
            }}
            scheduleDates={courseScheduleData}
            duration={course?.duration}
            reloadGridFunction={reloadGridFunction}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideCourseScheduleModal()}
        title={`Course Schedule/Assign: ${course.text}`}
        maxWidth="sm"
      />
    );
  };

  const hideCourseScheduleModal = () => {
    setCourseSchedule(null);
    // fetchData();
  };

  const showCourseAssignModal = async (test) => {
    console.log(test?.testId, "testId");

    setCourseSchedule(
      <CustomModal
        modal={true}
        setModal={() => hideCourseScheduleModal()}
        padding1={true}
        content={
          <ContentCuratorCourseScheduleComponent
            course={test}
            afterFinish={() => {
              hideCourseScheduleModal();
            }}
            reloadGridFunction={reloadGridFunction}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideCourseScheduleModal()}
        title={``} //Test Schedule/Assign:
        maxWidth="sm"
      />
    );
  };

  const assignCoruse = async (course) => {
    // setIsScheduling(true);
    const startDateTime = null; //new Date(scheduleStartDateValue);
    const endDateTime = null; //new Date(scheduleEndDateValue);
    const assignedDateTime = new Date();
    let response = await assignCourseTests.assignCourseTests(
      course?.courseId,
      startDateTime,
      endDateTime,
      assignedDateTime
    );

    // setIsScheduling(false);
    if (response.status == 200 || response.status == 204) {
      setAlert(null);
      showSnackBar("Test Series assigned successfully");
      // setTimeout(() => {
      //   setAlert(null)
      // }, 2000);
    } else if (response?.status == 400 || response?.status == 404) {
      console.log(response?.messege, "inerror");
      showSnackBar("Error occured", "error");
    }
  };

  const showCourseUserMapModal = (course) => {
    setCourseUserMap(
      <CustomModal
        modal={true}
        setModal={() => hideCourseUserMapModal()}
        content={
          <CourseUserMappingGrid
            state={course}
            afterFinish={() => {
              hideCourseUserMapModal();
            }}
            procureCount={
              course.testSeriesStudentsCount == 0
                ? null
                : course.testSeriesStudentsCount
            }
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideCourseUserMapModal()}
        title={`Course User Mapping for: ${course.text}`}
        maxWidth="lg"
        fullWidth={true}
      />
    );

    const hideCourseUserMapModal = () => {
      setCourseUserMap(null);
    };
  };

  React.useEffect(() => {
    let cols = [
      {
        field: "courseId",
        headerName: "Id",
        editable: false,
        hide: true,
        width: 100,
      },
      {
        field: "code",
        headerName: "Code",
        editable: false,
        width: 180,
      },
      {
        field: "image",
        headerName: "image",
        editable: false,
        hide: true,
      },
      {
        field: "categories",
        headerName: "Categories",
        editable: false,
        hide: true,
        width: 150,
      },
      {
        field: "text",
        headerName: "Name",
        editable: false,
        width: 240,
        renderCell: (cellValues) => {
          console.log(cellValues, "cells");
          return (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={cellValues.row.text ?? "none"}
            >
              <span>
                {cellValues.row.text
                  ? cellValues.row.text //?.substr(0, 22)
                  : "none"}
              </span>
            </Tooltip>
          );
        },
      },
      {
        field: "providerName",
        headerName: "Provider Name",
        editable: false,
        width: 200,
        resizable: true,
      },
      {
        field: "status",
        headerName: "Status",
        editable: false,
        width: 120,
        renderCell: (cellValues) => {
          return (
            <span>
              {
                cellValues.row.status == 1
                  ? "Draft"
                  : cellValues.row.status == 2
                    ? "Ready"
                    : cellValues.row.status == 3
                      ? "Published"
                      : cellValues.row.status == 4
                        ? "Scheduled"
                        : cellValues.row.status == 5
                          ? "In Progress"
                          : cellValues.row.status == 6
                            ? "Completed"
                            : cellValues.row.status == 7
                              ? "Terminated"
                              : "Draft"
                // :"Terminated"
              }
            </span>
          );
        },
      },
      // {
      //   field: "isActive",
      //   headerName: "Is Live",
      //   editable: false,
      //   width: 150,
      // },
      {
        field: "difficultyLevel",
        headerName: "Difficulty Level",
        editable: false,
        width: 180,
        renderCell: (cellValues) => {
          if (!cellValues.row.difficultyLevel) {
            return "Medium";
          }
          return cellValues.row.difficultyLevel == "1"
            ? "Easy"
            : cellValues.row.difficultyLevel == "2"
              ? "Medium"
              : "Difficult";
        },
      },
      {
        field: "testSubscriptionStatus",
        headerName: "Subscription Type",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          if (cellValues.row?.testSubscriptionStatus == 2) {
            return "Trail";
          } else {
            return "Subscription";
          }
        },
      },
      {
        field: "objective",
        headerName: "Objective",
        editable: false,
        width: 150,
      },
      {
        field: "targetAudience",
        headerName: "Target Audience",
        editable: false,
        width: 180,
      },
      {
        field: "scheduleDate",
        headerName: "Scheduled Date",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          if (cellValues.row.scheduleDate) {
            console.log(
              typeof cellValues.row.scheduleDate,
              "dates",
              cellValues.row.scheduleDate
            );

            function getDiff() {
              const currentDate = new Date(); // Current date in local timezone
              const currentUtcDate = new Date(currentDate.toUTCString());

              const timezoneOffsetLocal = currentDate.getTimezoneOffset(); // Offset in minutes
              const timezoneOffsetUTC = currentUtcDate.getTimezoneOffset(); // Offset in minutes

              const offsetHoursLocal = Math.abs(timezoneOffsetLocal / 60);
              const offsetMinutesLocal = Math.abs(timezoneOffsetLocal % 60);

              const offsetHoursUTC = Math.abs(timezoneOffsetUTC / 60);
              const offsetMinutesUTC = Math.abs(timezoneOffsetUTC % 60);

              console.log(
                `UTC offset: ${offsetHoursUTC} hours ${offsetMinutesUTC} minutes`
              );
              console.log(
                `Local timezone offset: ${offsetHoursLocal} hours ${offsetMinutesLocal} minutes`
              );
              return offsetHoursLocal;
            }
            // converting into local time start
            function formatDateToCustomString(date) {
              const months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ];
              const day = String(date.getDate()).padStart(2, "0");
              const month = months[date.getMonth()];
              const year = date.getFullYear();
              // let hours = date.getHours();
              let hours = String(date.getHours()).padStart(2, "0");
              const minutes = String(date.getMinutes()).padStart(2, "0");
              const seconds = String(date.getSeconds()).padStart(2, "0");
              const meridiem = hours >= 12 ? "PM" : "AM";
              hours %= 12;
              hours = hours || 12; // Handle midnight (0 hours)

              return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${meridiem}`;
            }
            const utcDateTime = new Date(cellValues.row.scheduleDate);
            let diff = getDiff();
            console.log(diff, "inDiff");
            const istOffset = diff * 60 * 60 * 1000; // IST is UTC+5.5 hours
            const istDateTime = new Date(utcDateTime.getTime() + istOffset);
            console.log("originalCellrows", cellValues.row);
            console.log(
              istDateTime,
              "convertedDate",
              "original",
              cellValues.row.scheduleDate
            );
            const formattedDateTime = formatDateToCustomString(istDateTime);
            return formattedDateTime;

            // 2.
            // timeDiff();

            // const dateTime = new Date(cellValues.row.scheduleDate);

            // const utcDateTimeString = '2024-07-16T12:34:56Z'; // Example UTC datetime string
            // const utcDate = new Date(dateTime);

            // const options = {
            //   day: '2-digit',
            //   month: 'short',
            //   year: 'numeric',
            //   hour: '2-digit',
            //   minute: '2-digit',
            //   second: '2-digit',
            //   hour12: true // Use 12-hour format (AM/PM)
            // };

            // const localDateTimeString = utcDate.toLocaleString(undefined, options);
            // console.log(localDateTimeString, 'localTimeString')
            // return localDateTimeString;

            //3.
            // const dateTime = new Date(cellValues.row.scheduleDate);
            // // Get individual date and time components
            // const day = dateTime.getDate();
            // const month = dateTime.toLocaleString('default', { month: 'short' });
            // const year = dateTime.getFullYear();
            // let hours = dateTime.getHours();
            // const minutes = String(dateTime.getMinutes()).padStart(2, '0');
            // const seconds = String(dateTime.getSeconds()).padStart(2, '0');
            // const ampm = hours >= 12 ? 'PM' : 'AM';
            // hours = hours % 12;
            // hours = String(hours ? hours : 12).padStart(2, '0');

            // return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
          } else {
            return "none";
          }
          // return cellValues.row.scheduleDate
          //   ? cellValues.row.scheduleDate.slice(0, 19).replace("T", " ")
          //   : <i> none </i>;
        },
      },
      // {
      //   field: "isDynamic",
      //   headerName: "Is Dynamic",
      //   editable: false,
      //   width: 150,
      // },
      {
        field: "duration",
        headerName: "Duration",
        editable: false,
        width: 200,
        hide: true,
      },
      // {
      //   field: "expiryDate",
      //   headerName: "Expiry Date",
      //   editable: false,
      //   hide: true,
      //   width: 200,
      // },
      {
        field: "action",
        headerName: "Actions",
        editable: false,
        width: 250,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => (
          <>
            {/* will add previlges for play button  */}
            <Tooltip title="Play ">
              <Button
                size="sm"
                justIcon
                round
                color="reddit"
                onClick={(e) => {
                  e.preventDefault();
                  setAlert(
                    <SweetAlert
                      style={{ display: "block", marginTop: "-100px" }}
                      title={" "}
                      onConfirm={() =>
                        PlayTestSeries(cellValues.row.courseId)
                      }
                      showCancel={true}
                      cancelBtnText={"CANCEL"}
                      confirmBtnText={
                        playCourseTests.isLoading == true ? "loading" : "OK"
                      }
                      onCancel={() => setAlert(null)}
                      confirmBtnCssClass={
                        classes.button + " " + classes.success
                      }
                      cancelBtnCssClass={
                        classes.button + " " + classes.warning
                      }
                    >
                      <div>
                        <div style={{ paddingInline: "7%", color: "black" }}>
                          <h4 style={{ fontWeight: "500", color: "brown" }}>
                            Warning
                          </h4>
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              textAlign: "left",
                            }}
                          >
                            <li>
                              Tests which are not assigned to the students yet will be assigned.
                              <br />
                            </li>
                          </ul>
                        </div>
                        <br />
                        <small>
                          {" "}
                          <ul
                            style={{
                              listStyleType: "none",
                              textAlign: "left",
                              color: "black",
                              fontWeight: "500",
                            }}
                          >
                            <li>
                              Are you sure you want to assign test?
                            </li>
                          </ul>
                        </small>
                      </div>
                    </SweetAlert>
                  );
                }}
                disabled={
                  cellValues.row.providerId == providerId
                    ? false
                    : true
                }
              >
                <PlayArrowOutlined />
              </Button>
            </Tooltip>

            {isPrivilege("UpdateTestSeries") == true ? (
              <Tooltip title="Edit">
                <Button
                  size="sm"
                  justIcon
                  round
                  // color="reddit"
                  onClick={(e) => {
                    e.preventDefault();

                    showCourseEditModal(cellValues.row);
                  }}
                  style={{ size: "1px", backgroundColor: "#DD4B39" }}
                  disabled={
                    cellValues.row.providerId == providerId
                      ? false
                      : true
                  }
                // disabled={cellValues.row.providerId == providerId ? cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : cellValues.row.status >= 4 ? true : false : true}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}

            <Tooltip title="Upload resourceImage">
              <Button
                size="sm"
                justIcon
                round
                onClick={(e) => {
                  setResourImageModal(
                    <CustomModal
                      modal={true}
                      setModal={() => hideImageCustomModal()}
                      content={
                        <div style={{
                          width: '370px', height: '200px', padding: '16px', flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          {/* <h3>Upload Image</h3> */}
                          <GridItem xs={12} sm={12} md={3} >
                            <ImageUpload source={image.imageData} setImageValue={(e) => {
                              handleChangeLangauage({
                                target: {
                                  name: "imageData",
                                  value: e == '/static/media/image_placeholder.61b9ec86.jpg' ? null : e,
                                },
                              });
                            }} />
                          </GridItem>
                        </div>
                      }
                      cancelButtonMessage="Cancel"
                      okButtonMessage={uploadResourceImage?.isLoading ? "Uploading..." : "Upload"}
                      showOk={true}

                      onCancelClick={() => hideImageCustomModal()}
                      title="Upload ResourseImage"
                      maxWidth="sm"
                      onOkClick={() => { uploadImage(cellValues.row.courseId) }}
                      showCancel={true}
                      padding1={true}
                    />
                  );
                }}
                style={{ size: "1px", backgroundColor: "#DD4B39" }}
              // disabled={cellValues.row.providerId == providerId ? cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : cellValues.row.status >= 4 ? true : false : true}
              >
                <CloudUploadRounded />
              </Button>
            </Tooltip>

            {isPrivilege("PreviewTestSeries") == true ? (
              <Tooltip title="View Tests">
                <Button
                  size="sm"
                  justIcon
                  round
                  onClick={(e) => {
                    e.preventDefault();

                    showAssignedTests(cellValues.row);
                  }}
                  style={{ size: "1px", backgroundColor: "#DD4B39" }}
                // disabled={cellValues.row.providerId == providerId ? cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : cellValues.row.status >= 4 ? true : false : true}
                //disabled={cellValues.row.providerId == providerId ? cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : false : true}  // in above condition we had status condtion for disable but we have removed it now
                >
                  <VisibilitySharp />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            {isPrivilege("PublishTestSeries") == true ? (
              <Tooltip title="Publish">
                <Button
                  size="sm"
                  justIcon
                  round
                  color="reddit"
                  onClick={(e) => {
                    e.preventDefault();
                    setAlert(
                      <SweetAlert
                        style={{ display: "block", marginTop: "-100px" }}
                        title={" "}
                        onConfirm={() =>
                          publishTestSeries(cellValues.row.courseId)
                        }
                        showCancel={true}
                        cancelBtnText={"CANCEL"}
                        confirmBtnText={
                          publishCourse.isLoading == true ? "Publishing" : "OK"
                        }
                        onCancel={() => setAlert(null)}
                        confirmBtnCssClass={
                          classes.button + " " + classes.success
                        }
                        cancelBtnCssClass={
                          classes.button + " " + classes.warning
                        }
                      >
                        {/* <div style={{ display: 'inline-flex' }}>
                            <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                            <h4> Are you sure you want to Publish the selected test series? Once published, no further edits will be allowed. Please review your content and ensure it's ready for public access.
                              Note: You can't make changes after publishing. Double-check before confirming</h4>
                          </div> */}
                        <div>
                          <div style={{ paddingInline: "7%", color: "black" }}>
                            <h4 style={{ fontWeight: "500", color: "brown" }}>
                              Warning
                            </h4>
                            <ul
                              style={{
                                listStyleType: "none",
                                padding: 0,
                                textAlign: "left",
                              }}
                            >
                              <li>
                                No further edits will be allowed in the selected
                                test series after Published.
                                <br />
                              </li>
                              <li>
                                {" "}
                                Please review and ensure that it's Ready for
                                public access. <br />
                              </li>
                            </ul>
                          </div>
                          <br />
                          <small>
                            {" "}
                            <ul
                              style={{
                                listStyleType: "none",
                                textAlign: "left",
                                color: "black",
                                fontWeight: "500",
                              }}
                            >
                              <li>
                                Are you sure you want to Publish the selected
                                test series?{" "}
                              </li>
                            </ul>
                          </small>
                        </div>
                      </SweetAlert>
                    );
                  }}
                  disabled={
                    cellValues.row.providerId == providerId
                      ? cellValues.row.status > 1
                        ? false
                        : true
                      : true
                  }
                // disabled={cellValues.row.providerId == providerId ? cellValues.row.isLocked == true ? (cellValues.row.lockedBy == user ? false : true) : cellValues.row.status >= 1 ? true : false : true}
                >
                  <PublicIcon />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            {/* <PreviewComponent testId={cellValues.row.testId} multiple={true} /> */}
            {isPrivilege("AssignStudents") == true ? (
              <Tooltip title="Assign">
                <Button
                  size="sm"
                  justIcon
                  round
                  // color="google"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("cellValues", cellValues);
                    showCourseUserMapModal(cellValues.row);
                  }}
                  style={{ size: "1px", backgroundColor: "#DD4B39" }}
                >
                  <PersonAddIcon />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            {isPrivilege("AssignTestSeries") == true ? (
              <Tooltip title="Schedule">
                <Button
                  size="sm"
                  justIcon
                  round
                  //disabled={!cellValues.row.isActive}
                  // color="twitter"
                  onClick={(e) => {
                    e.preventDefault();
                    showCourseScheduleModal(cellValues.row);
                  }}
                  // style={cellValues.row.scheduleDate != null ? { size: "1px", backgroundColor: "#53AD57" } : { size: "1px", backgroundColor: "#DD4B39" }}
                  style={
                    cellValues.row.scheduleStatus == 4
                      ? { size: "1px", backgroundColor: "#53AD57" }
                      : cellValues.row.scheduleStatus == 5
                        ? { size: "1px", backgroundColor: "#FD940C" }
                        : { size: "1px", backgroundColor: "#dd4b39" }
                  }
                  disabled={
                    (cellValues.row.isLocked == true
                      ? true
                      : !cellValues.row.isActive) || cellValues.row.status == 1
                  }
                >
                  <ScheduleIcon />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}

            {isPrivilege("AssignTestSeriesForContentCurator") == true ? (
              <Tooltip title="Schedule">
                <Button
                  size="sm"
                  justIcon
                  round
                  //disabled={!cellValues.row.isActive}
                  // color="twitter"
                  onClick={(e) => {
                    e.preventDefault();
                    showCourseAssignModal(cellValues.row);
                    // setAlert(
                    //   <SweetAlert
                    //     style={{ display: "block", marginTop: "-100px" }}
                    //     title={' '}
                    //     onConfirm={() => assignCoruse(cellValues.row)}
                    //     showCancel={true}
                    //     cancelBtnText={'CANCEL'}
                    //     confirmBtnText={'OK'}
                    //     onCancel={() => setAlert(null)}
                    //     confirmBtnCssClass={classes.button + " " + classes.success}
                    //     cancelBtnCssClass={classes.button + " " + classes.warning}
                    //   >
                    //     <div style={{ display: 'inline-flex' }}>
                    //       <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                    //       <h4> Are you sure you want to schedule test series?</h4>
                    //     </div>
                    //   </SweetAlert>
                    // );
                  }}
                  // style={
                  //   cellValues.row.scheduleDate != null
                  //     ? { size: "1px", backgroundColor: "#53AD57" }
                  //     : { size: "1px", backgroundColor: "#DD4B39" }
                  // }
                  style={
                    cellValues.row.scheduleStatus == 4
                      ? { size: "1px", backgroundColor: "#53AD57" }
                      : cellValues.row.scheduleStatus == 5
                        ? { size: "1px", backgroundColor: "#FD940C" }
                        : { size: "1px", backgroundColor: "#dd4b39" }
                  }
                  disabled={
                    (cellValues.row.isLocked == true
                      ? true
                      : !cellValues.row.isActive) || cellValues.row.status == 1
                  }
                >
                  <ScheduleIcon />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            {/* {!cellValues.row.isLocked ? (
            <Tooltip title="Lock">
              <Button
                size="sm"
                justIcon
                round
                // color="twitter"
                onClick={(e) => {
                  e.preventDefault();
                  lockUnlock.mutate({ id: cellValues.row.testId, lock: true });
                }}
                disabled = {cellValues.row.status >= 4 ? true : false}
              >
                <Lock />
              </Button>
            </Tooltip>
            ) : (
              <Tooltip title="Unlock">
                <Button
                  size="sm"
                  justIcon
                  round
                  color="white"
                  onClick={(e) => {
                    e.preventDefault();
                    lockUnlock.mutate({ id: cellValues.row.testId, lock: false });
                  }}
                  disabled = {cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : false}
                >
                  <LockOpenIcon />
                </Button>
              </Tooltip>
            )} */}
            {isPrivilege("DeleteTestSeries") == true ? (
              <Tooltip title="Delete">
                <Button
                  size="sm"
                  justIcon
                  round
                  color="reddit"
                  onClick={(e) => {
                    e.preventDefault();
                    setAlert(
                      <SweetAlert
                        style={{ display: "block", marginTop: "-100px" }}
                        title={" "}
                        onConfirm={() =>
                          removeTestSeries(cellValues.row.courseId)
                        }
                        showCancel={true}
                        cancelBtnText={"CANCEL"}
                        confirmBtnText={"OK"}
                        onCancel={() => setAlert(null)}
                        confirmBtnCssClass={
                          classes.button + " " + classes.success
                        }
                        cancelBtnCssClass={
                          classes.button + " " + classes.warning
                        }
                      >
                        <div style={{ display: "inline-flex" }}>
                          <WarningOutlined
                            style={{
                              marginTop: "10px",
                              marginRight: "5px",
                              color: "brown",
                            }}
                          />
                          <h4>
                            {" "}
                            Are you sure you want to delete the test series?
                          </h4>
                        </div>
                      </SweetAlert>
                    );
                  }}
                  disabled={
                    cellValues.row.providerId == providerId
                      ? cellValues.row.isLocked == true
                        ? cellValues.row.lockedBy == user
                          ? false
                          : true
                        : cellValues.row.scheduleStatus >= 4
                          ? true
                          : false
                      : true
                  }
                >
                  <DeleteForeverIcon />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            {/* <Tooltip title="Clean Data">
              <Button
              size="sm"
              justIcon
              round
              color="reddit"
              onClick={(e) => {
                e.preventDefault();
                setAlert(
                  <SweetAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={''}
                    onConfirm={() =>cleanupUsers(cellValues.row.courseId) }
                    showCancel={true}
                    cancelBtnText={'CANCEL'}
                    confirmBtnText={'OK'}
                    onCancel={() => setAlert(null)}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.warning}
                  >
                    <div style={{display:'inline-flex'}}>
                      <WarningOutlined style={{marginTop:'10px',marginRight:'5px',color:'brown'}}/>
                      <h4> Are you sure you want to cleanup the Series?</h4>
                    </div>
                  </SweetAlert>
                );
              }}
              disabled={cellValues.row.status < 4}
              >
                <WarningOutlined/>
              </Button>
            </Tooltip> */}
          </>
        ),
      },
    ];

    setColumns(cols);
  }, []);

  const handleEdit = (p, e) => { };

  const onCellEditCommit = (p) => {
    let row = questionData.questions.find((x) => x.id == p.id);
    if (row[p.field] == p.value) {
      return;
    }
  };

  const handleRowSelection = (e) => {
    setSelection(e);
  };

  const getRowClassName = (row) => {
    if (row.isLocked == true) {
      return "gray";
    }

    return null;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(null);
    setOpen(false);
  };

  const showSnackBar = (message, sev) => {
    setSeverity(sev ?? "success");
    setAlertMessage(message);
    setOpen(true);
  };

  React.useEffect(() => {
    console.log(deleteCourse, "successf");
    if (deleteCourse.isSuccess == true) {
      showSnackBar("Test Series deleted successfully");
      setAlert(null);
      // refetch();
    }
  }, [deleteCourse.isSuccess]);

  React.useEffect(() => {
    console.log(publishCourse, "publishCourseSuccess");
    if (publishCourse.isSuccess == true) {
      showSnackBar("Test Series published successfully");
      setCoursePageState((old) => ({ ...old, isLoading: false }));
      setAlert(null);
      // refetch();
    }
  }, [publishCourse.isSuccess]);

  React.useEffect(() => {
    console.log(playCourseTests, "publishCourseSuccess");
    if (playCourseTests.isSuccess == true) {
      showSnackBar("Newly test assigned successfully");
      setCoursePageState((old) => ({ ...old, isLoading: false }));
      setAlert(null);
      // refetch();
    }
  }, [playCourseTests.isSuccess]);

  React.useEffect(() => {
    console.log(deleteCourse, "successf");
    if (deleteCourse.isError == true) {
      showSnackBar(deleteCourse?.error?.response?.data, "error");
      setAlert(null);
    }
  }, [deleteCourse.isError]);

  React.useEffect(() => {
    console.log(publishCourse, "publishCourseError");
    if (publishCourse.isError == true) {
      showSnackBar(publishCourse?.error?.response?.data, "error");
      setAlert(null);
    }
  }, [publishCourse.isError]);

  React.useEffect(() => {
    console.log(playCourseTests, "publishCourseError");
    if (playCourseTests.isError == true) {
      showSnackBar(playCourseTests?.error?.response?.data, "error");
      setAlert(null);
    }
  }, [playCourseTests.isError]);

  React.useEffect(() => {
    console.log(cleanupTestUsers, "successf");
    if (cleanupTestUsers.isSuccess == true) {
      showSnackBar("Test Series Cleanup successfully");
      setAlert(null);
      // refetch();
    }
  }, [cleanupTestUsers.isSuccess]);

  React.useEffect(() => {
    console.log(cleanupTestUsers, "successf");
    if (cleanupTestUsers.isError == true) {
      showSnackBar(cleanupTestUsers?.error?.response?.data, "error");
      setAlert(null);
    }
  }, [cleanupTestUsers.isError]);
  const handleAlignment = (event, content) => {
    console.log(content, "contentFilterVals2");
    setContentFilter(content);
  };

  return (
    <Card style={{ height: 700 }}>
      {alert}
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <ListAltOutlined />
        </CardIcon>
      </CardHeader>
      <CardBody>
        {/* <h1>Test Series</h1> */}

        <GridContainer>
          {/* <GridItem xs={12} sm={12} md={2} style={{ textAlign: "right" }}>
          </GridItem> */}
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
            <ToggleButtonGroup
              value={contentFilter}
              color="secondary"
              size="small"
              exclusive
              onChange={handleAlignment}
              aria-label="content"
              style={{ marginRight: 10, marginTop: 20 }}
            >
              <ToggleButton value={"0"} aria-label="All content">
                All
              </ToggleButton>
              <ToggleButton value={"1"} aria-label="Owned content">
                Owned
              </ToggleButton>
              <ToggleButton value={"2"} aria-label="Subscribed content">
                Subscribed
              </ToggleButton>
            </ToggleButtonGroup>
            {console.log(
              "licensePackagetest",
              coursePageState?.total,
              licensePackageObject?.Tests
            )}
            {isPrivilege("CreateTestSeries") == true ? (
              <Button
                color="primary"
                onClick={() => {
                  coursePageState?.total + 1 > licensePackageObject?.TestSeries
                    ? showSnackBar(
                      `Your current package allows up to ${licensePackageObject?.TestSeries} test series.Please upgrade to higher package to create additional test series.`,
                      "warning"
                    )
                    : props.history.push("/admin/course");
                }}
                disabled={coursePageState.isLoading}
                style={{
                  marginTop: 20,
                  marginBottom: 21,
                  textTransform: "none",
                }}
              >
                + New
              </Button>
            ) : (
              ""
            )}
          </GridItem>
        </GridContainer>

        {coursePageState.isLoading == false && coursePageState.data ? (
          <div>
            <div style={{ height: 600, width: "100%" }}>
              <CustomDataGrid
                rows={coursePageState.data}
                rowCount={coursePageState.total}
                loading={coursePageState.isLoading}
                rowsPerPageOptions={[10, 30, 50, 70, 100]}
                page={coursePageState.page - 1}
                pageSize={coursePageState.pageSize}
                paginationMode="server"
                onPageChange={(newPage) =>
                  setCoursePageState((old) => ({ ...old, page: newPage + 1 }))
                }
                onPageSizeChange={(newPageSize) =>
                  setCoursePageState((old) => ({
                    ...old,
                    pageSize: newPageSize,
                  }))
                }
                columns={columns}
                onEditCellPropsChange={handleEdit}
                onCellEditCommit={onCellEditCommit}
                onSelectionModelChange={handleRowSelection}
                isRowSelectable={(params: GridRowParams) =>
                  params.row.providerId == providerId ? true : false
                }
                checkboxSelection
                getRowClassName={getRowClassName}
              />
            </div>

            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert onClose={handleClose} severity={severity}>
                {alertMessage}
              </Alert>
            </Snackbar>
          </div>
        ) : (
          <div style={{ height: 650, width: "100%" }}>
            <CustomDataGrid
              rows={[]}
              loading={coursePageState.isLoading}
              columns={columns}
              checkboxSelection
              getRowClassName={getRowClassName}
            />
            {/* <CircularProgress style={{marginInline:'45%'}}/>
          <h6 style={{marginInline:'45%'}}>Loading...</h6> */}
          </div>
        )}
      </CardBody>

      {CourseSchedule}
      {CourseUserMap}
      {resourceImageModal}
    </Card>
  );
}

export default withRouter(CourseHomeComponent);
