
import axios from "axios";
import { configUrl } from "config";

const getAggregatorResources = async (ProviderId) => {
    return axios
        .get(`${configUrl.AssessmentServer}/api/ProviderResourceMapping/getAggregatorResources/${ProviderId}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error?.response?.data, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status,
            };
        });
};
export default {
    getAggregatorResources: getAggregatorResources,
};
