
import axios from "axios";
import { configUrl } from "config";
import { useMutation, useQueryClient } from "react-query";
export default function useUploadResourceImage() {
    let queryClient = useQueryClient();
    return useMutation(
        async (courseData) => {
            let courseId = courseData.courseId;
            let image = courseData.imageData;

            await axios.post(
                `${configUrl.AssessmentServer}/api/ProviderResourceMapping/uploadResourceImage/${courseId}`,
                { "resourceImage": image },
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("uploadResourceImage");
            },
            onError: () => {
                queryClient.invalidateQueries("uploadResourceImage");
            },
        }
    );
}
