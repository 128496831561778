import axios from "axios";
import { configUrl } from "config.js";

const deleteTestMappedToCourse = async (courseId, testId) => {
    console.log(courseId, 'courseIdinHook')

    try {
        let response = await axios.delete(`${configUrl.AssessmentServer}/api/CourseTestMappings/deleteTestMappedToCourse/${courseId}/${testId}`)
        console.log(response, response?.data, 'deleteTestResponse')
        return response?.data;
    } catch (error) {
        const errorMessage = error.response?.data || 'An error occurred';
        console.log(errorMessage, error.response?.data, 'deleteTestError');
        console.log(errorMessage, error.response, 'deleteTestError1');
        return errorMessage;
    }
}

export default {
    deleteTestMappedToCourse: deleteTestMappedToCourse
}
