import { Box, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import { LoginContext } from "contexts/LoginContext";
import { useContext, useEffect, useState } from "react";
import getAggregatorResources from "services/ProviderServices/getAggregatorResources";
import getAllCourses from "services/TestSeriesService/getAllCourses";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
        },
    },
    input: {
        width: "100px",
    },
    errorInput: {
        borderColor: "red", // Highlighting error field
    },
}));

const AggregatorProductsListing = ({ afterFinish }) => {
    const classes = useStyles();
    const { user, providerId, providerType } = useContext(LoginContext);
    const [testSeries, setTestSeries] = useState([]);
    const [selectedSeries, setSelectedSeries] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [errors, setErrors] = useState({});

    const fetchExistingMappings = async () => {
        try {
            const response = await getAggregatorResources.getAggregatorResources(providerId);
            const mappings = response.data.$values || [];
            const formattedMappings = mappings.reduce((acc, item) => {
                acc[item.resourceId] = {
                    testsCount: item.procureStudentsCount || 0,
                    availableStudentsCount: item.licenseStudentsCount || 0,
                };
                return acc;
            }, {});
            setSelectedSeries(formattedMappings);
        } catch (error) {
            console.error("Error fetching provider resource mapping:", error);
        }
    };

    useEffect(() => {
        fetchExistingMappings();
    }, [providerId]);

    const fetchTestSeries = async (pageNumber, pageSize) => {
        setLoading(true);
        try {
            const response = await getAllCourses.getAllCourses(
                pageNumber,
                pageSize,
                2);
            const data = response || [];
            setTestSeries(data);
            setTotalRows(response.totalCount || 0);
        } catch (error) {
            console.error("Error fetching test series:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTestSeries(1, pageSize);
    }, [pageSize]);

    const columns = [
        { field: "providerName", headerName: "Content Partner", width: 250 },
        { field: "text", headerName: "Test Series", width: 300 },
        {
            field: "availableStudentsCount",
            headerName: "License Available",
            width: 200,
            renderCell: (params) => (
                <TextField
                    type="number"
                    className={classes.input}
                    value={selectedSeries[params.row.id]?.availableStudentsCount || ""}
                    disabled
                    InputProps={{
                        disableUnderline: true, // as discussed with sir removes the underline border
                        style: {
                            border: "none",
                        },
                    }}
                />
            ),
        },
        {
            field: "testsCount",
            headerName: "Procure License *",
            width: 200,
            renderCell: (params) => (
                <TextField
                    type="number"
                    className={`${classes.input} ${errors[params.row.id] ? classes.errorInput : ""
                        }`}
                    value={selectedSeries[params.row.id]?.testsCount || ""}
                    disabled={true}
                    error={!!errors[params.row.id]} // Set error flag
                    helperText={errors[params.row.id] ? "Required" : ""}
                    InputProps={{
                        disableUnderline: true, // as discussed with sir removes the underline border
                        style: {
                            border: "none",
                        },
                    }}
                />
            ),
        }
    ];

    return (
        <Box className={classes.root} style={{ height: 450, width: 950 }}>
            <CustomDataGrid
                rows={testSeries}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10]}
                rowCount={totalRows}
                pagination
                paginationMode="server"
                onPageChange={(params) => fetchTestSeries(params.page + 1, pageSize)}
                loading={loading}
                disableSelectionOnClick
            />
        </Box>
    );
};

export default AggregatorProductsListing;
