import axios from "axios";
import { configUrl } from "config.js";
import 'katex/dist/katex.min.css';

// Function to transform the API response
const transformApiResponse = (response, providerId, parent = null, level = 1) => {
    return response?.$values?.map(contentPartner => ({
        id: contentPartner.$id,
        name: contentPartner.contentPartnerName,
        parent: parent ? parent.id : null,
        isCategory: true,
        providerId: providerId,
        value: contentPartner.$id,
        label: contentPartner.contentPartnerName,
        currentLevel: level,
        children: contentPartner?.courses?.$values?.map(course => ({
            id: course.$id,
            name: course.courseName,
            categoryId: parseInt(contentPartner.$id, 10),
            parent: contentPartner.$id,
            isCategory: false,
            providerId: providerId,
            value: `T${course.$id}`,
            label: course.courseName,
            currentLevel: level + 1, // Automatically increment level
            children: course?.subscribers?.$values?.map(subscriber => ({
                id: subscriber.$id,
                name: subscriber.subscriberName,
                categoryId: parseInt(course.$id, 10),
                parent: `T${course.$id}`,
                isCategory: false,
                topicId: parseInt(subscriber.$id, 10),
                providerId: providerId,
                value: `T${subscriber.$id}`,
                label: subscriber.subscriberName,
                studentCount: subscriber.studentCount,
                createdDate: subscriber.createdDate,
                currentLevel: level + 2, // Automatically increment level further for subscribers
                children: [] // Add children if necessary
            }))
        }))
    }));
};

const aggrigatorTransactionsService = async (providerId) => {

    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/ProviderResourceMapping/getAggregatorTransactions/${providerId}`);

    console.log(response.data, "respon45");
    const mappings = response.data || [];
    let resp = transformApiResponse(mappings, providerId);
    console.log(resp, "respdata");
    return resp;
};
export default {
    aggrigatorTransactionsService: aggrigatorTransactionsService,
}


