import axios from 'axios';
import { configUrl } from "config.js";

const getAggregatorDashboardCount = async () => {
    try {
        const response = await axios.get(`${configUrl.AssessmentServer}/api/ProviderResourceMapping/AggregatorDashboardCount`);
        return response.data;
    } catch (error) {
        console.error('Error calling AggregatorDashboardCount API:', error);
    }
};

export default getAggregatorDashboardCount;
