import React, { useContext, useEffect, useState } from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import InfoOutline from "@material-ui/icons/InfoOutline";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { Description, Help, ListAlt, ListAltOutlined, School } from "@material-ui/icons";
import PeopleIcon from '@material-ui/icons/People';
import { LoginContext } from "contexts/LoginContext";
import useGetCourseCount from "hooks/courseHooks/useGetCourseCount";
import useGetTrendingTestSeriesData from "hooks/courseHooks/useGetTrendingTestSeriesData";
import useGetQuestionsCount from "hooks/questionHooks/useGetQuestionsCount";
import useGetTestsCount from "hooks/testsHooks/useGetTestsCount";
import useGetAllUserCount from "hooks/userHooks/useGetAllUserCount";
import CustomModal from "NewComponents/CustomModal/CustomModal";
import { withRouter } from "react-router-dom";
import getAggregatorDashboardCount from "services/ApplicationUser/AggregatorDashboardCount";
import AggregatorProductsListing from "views/Components/AggregatorProductsListing";

const us_flag = require("assets/img/flags/US.png").default;
const de_flag = require("assets/img/flags/DE.png").default;
const au_flag = require("assets/img/flags/AU.png").default;
const gb_flag = require("assets/img/flags/GB.png").default;
const ro_flag = require("assets/img/flags/RO.png").default;
const br_flag = require("assets/img/flags/BR.png").default;

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const classes = useStyles();

  const { data: courseCount } = useGetCourseCount();

  const { data: testsCount } = useGetTestsCount();

  const { data: questionsCount } = useGetQuestionsCount();

  const { data: usersCount } = useGetAllUserCount();

  const { data: trend } = useGetTrendingTestSeriesData();

  const { user, userPrivileges, setTestPageState, setQuestionPageState, setCoursePageState, providerId } = useContext(LoginContext);


  console.log("skdva", userPrivileges);
  const [trendData, setTrendData] = React.useState([["Test Series", "Tests"]]);
  const [totalProvidersCount, setTotalProvidersCount] = useState(0);
  const [provisionedLicensesCount, setProvisionedLicensesCount] = useState(0);
  const [assignedLicensesCount, setAssignedLicensesCount] = useState(0);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [interestsEditForm, setInterestsEditForm] = useState(null);


  React.useEffect(() => {
    setTestPageState({ pageSize: 10, page: 1 });
    setQuestionPageState({ pageSize: 10, page: 1 });
    setCoursePageState({ pageSize: 10, page: 1 })

  }, [])
  React.useEffect(() => {
    if (trend) {
      let tData = [["Test Series", "Tests"]]

      trend.forEach((x) => {
        tData.push([x.name, x.tests]);
      });

      setTrendData([...tData])
    }
  }, [trend]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await getAggregatorDashboardCount();
        setTotalProvidersCount(result?.totalProvidersCount || 0);
        setProvisionedLicensesCount(result?.provisionedLicensesCount || 0);
        setAssignedLicensesCount(result?.assignedLicensesCount || 0);
        setTotalProductsCount(result?.totalProductsCount || 0);
      } catch (err) {
        console.log("error ", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.SimplifiedTest.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  const productsFormModal = () => {
    props.history.push("/admin/transactions");
  };

  const resourceFormModal = () => {
    console.log("clicked row");
    setInterestsEditForm(
      <CustomModal
        modal={true}
        setModal={() => hideInterestsEditForm()}
        padding1={true}
        content={
          <AggregatorProductsListing
            afterFinish={() => {
              hideInterestsEditForm();
            }}
          />
        }
        showOk={false}
        showCancel={false}
        showHeaderBackground={true}
        title={`Subscriptions`}
        maxWidth="lg"
        onCancelClick={() => hideInterestsEditForm()}
      />
    );
  };
  const hideInterestsEditForm = () => {
    setInterestsEditForm(null);
  };

  const isPrivilegeforStudent = (x) => {
    if (userPrivileges?.Privileges?.Student.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  const isPrivilegeForTestSeriesShown = () => {
    if (userPrivileges?.PackageId == 3) {
      return true;
    }
    else {
      return false;
    }
  }
  const isPrivilegeforTestSeries = (x) => {
    if (userPrivileges?.Privileges?.TestSeries.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  const isPrivilegeforQuestions = (x) => {
    if (userPrivileges?.Privileges?.Question.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <div>
      <GridContainer>
        {isPrivilegeForTestSeriesShown() == true ?
          (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              {(userPrivileges?.Roles?.length > 0 && userPrivileges?.Roles[0]?.Id != 7) ?
                <Card
                  onClick={() => {
                    isPrivilegeforTestSeries("ViewTestSeries") == true ?
                      props.history.push("/admin/testSeries") : console.log('this is quick test')
                  }}

                  style={{ cursor: "pointer" }}
                >
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <ListAlt />
                    </CardIcon>
                    <p className={classes.cardCategory}></p>
                    <h4 className={classes.cardTitle}>
                      Test Series ({courseCount ?? "..."})
                    </h4>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      See Details...
                      {/* <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()} */}
                    </div>
                  </CardFooter>
                </Card>
                :
                (
                  <Card                                       // Cards for aggreagtor
                    onClick={() => {
                      //   isPrivilege("ViewProviderDashboard") == true ?
                      props.history.push("/admin/providerlist")
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <CardHeader color="danger" stats icon>
                      <CardIcon color="danger">
                        <PeopleIcon />
                      </CardIcon>
                      <p className={classes.cardCategory}></p>
                      <h5 className={classes.cardTitle}>
                        {"Providers"}  ({loading ? "..." : totalProvidersCount})
                      </h5>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        See Details...
                      </div>
                    </CardFooter>
                  </Card>
                )
              }
            </GridItem>
          )
          : ''
        }
        <GridItem xs={12} sm={6} md={6} lg={3}>
          {(userPrivileges?.Roles?.length > 0 && userPrivileges?.Roles[0]?.Id != 7) ?
            <Card
              onClick={() => {
                isPrivilege("ViewSimplifiedTest") == true ?
                  props.history.push("/admin/simplifiedtesthome") : props.history.push("/admin/testhome")
              }}
              style={{ cursor: "pointer" }}
            >
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ListAlt />
                </CardIcon>
                <p className={classes.cardCategory}></p>
                <h4 className={classes.cardTitle}>
                  {isPrivilege("ViewSimplifiedTest") == true ?
                    "Quick Tests" : "Tests"
                  }
                  ({testsCount ?? "..."})
                </h4>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  See Details...
                  {/* <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()} */}
                </div>
              </CardFooter>
            </Card>
            :
            (
              <Card                             // Cards for aggreagtor
                onClick={() => {
                  //props.history.push("/admin/providerlist")
                  resourceFormModal();
                }}

                style={{ cursor: "pointer" }}
              >
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <ListAlt />
                  </CardIcon>
                  <p className={classes.cardCategory}></p>
                  <h4 className={classes.cardTitle}>
                    Products ({loading ? "..." : totalProductsCount})
                  </h4>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    See Details...
                  </div>
                </CardFooter>
              </Card>
            )
          }
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          {(userPrivileges?.Roles?.length > 0 && userPrivileges?.Roles[0]?.Id != 7) ?
            <Card
              onClick={() => {
                isPrivilegeforQuestions("ViewQuestion") == true ?
                  props.history.push("/admin/questions") : console.log('this is simplified test')
              }}
              style={{ cursor: "pointer" }}
            >
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Help />
                </CardIcon>
                <p className={classes.cardCategory}></p>
                <h4 className={classes.cardTitle}>
                  Questions ({questionsCount ?? "..."})
                </h4>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  See Details...
                  {/* <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()} */}
                </div>
              </CardFooter>
            </Card>
            :
            (
              <Card                                     // Cards for aggreagtor
                onClick={() => {
                  //props.history.push("/admin/providerlist")
                  productsFormModal();
                }}
                style={{ cursor: "pointer" }}
              >
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Description />
                  </CardIcon>
                  <p className={classes.cardCategory}></p>
                  <h5 className={classes.cardTitle}>
                    Provision Licenses ({loading ? "..." : provisionedLicensesCount})
                  </h5>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    See Details...

                  </div>
                </CardFooter>
              </Card>
            )
          }
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          {console.log("previlges", userPrivileges, userPrivileges?.Roles, (userPrivileges?.Roles?.length > 0 && userPrivileges?.Roles[0]?.Id != 7))}
          {(userPrivileges?.Roles?.length > 0 && userPrivileges?.Roles[0]?.Id != 7) ?
            <Card
              onClick={() => {
                isPrivilegeforStudent("ViewStudent") == true ?
                  props.history.push("/admin/users") : console.log("simplified test click")
              }}

              style={{ cursor: "pointer" }}
            >
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <School />
                </CardIcon>
                <p className={classes.cardCategory}></p>
                <h4 className={classes.cardTitle}>
                  Students ({usersCount ?? "..."})
                </h4>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  See Details...
                  {/* <DateRange /> Updated{" "}
                {new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()} */}
                </div>
              </CardFooter>
            </Card>
            :
            (
              <Card                                          // Cards for aggreagtor
                onClick={() => {
                  props.history.push("/admin/providerlist")
                }}

                style={{ cursor: "pointer" }}
              >
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <School />
                  </CardIcon>
                  <p className={classes.cardCategory}></p>
                  <h5 className={classes.cardTitle}>
                    Assigned Licenses ({loading ? "..." : assignedLicensesCount})
                  </h5>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    See Details...
                  </div>
                </CardFooter>
              </Card>
            )
          }
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          {
            isPrivilegeforTestSeries("TrendingTestSeries") == true ?
              <Card>
                <CardHeader color="success" icon>
                  <CardIcon color="success">
                    <ListAltOutlined />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Trending Test Series</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent="space-between">
                    <GridItem xs={12} sm={12} md={5}>
                      <Table tableData={trendData} />
                    </GridItem>

                  </GridContainer>
                </CardBody>
              </Card> : null

          }
        </GridItem>
        {interestsEditForm}
      </GridContainer>

    </div>
  );
}

export default withRouter(Dashboard);
