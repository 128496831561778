import axios from "axios";
import { configUrl } from "config";
import { useMutation, useQueryClient } from "react-query";

export default function useCloneUsers() {
    let queryClient = useQueryClient();
    return useMutation(
        async (obj) => {
            console.log("uploading", obj);

            let newStudentsIds = obj?.selected;
            let existingStudentsId = obj?.selectedRow;
            await axios.post(
                `${configUrl.AssessmentServer}/api/Student/createTestDataClones/${existingStudentsId}`,
                newStudentsIds
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("cloneUsers");
            },
            onError: () => {
                queryClient.invalidateQueries("cloneUsers");
            }
        }
    );
}
