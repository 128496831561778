import axios from "axios";
import { configUrl } from "config.js";

import { useMutation, useQueryClient } from "react-query";
export default function usePlayCourseTests() {
    let queryClient = useQueryClient();
    return useMutation(
        async (courseId) => {
            await axios.put(
                `${configUrl.AssessmentServer}/api/Courses/playCourseTests/${courseId}`
            );
        },
        {
            onSuccess: () => {
                // queryClient.invalidateQueries("tests");
            },
        }
    );
}
