import React from "react";
// @material-ui/icons

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import useGetCategories from "hooks/categoryHooks/useGetCategories";
import DropdownTreeSelect from "react-dropdown-tree-select";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles);

const TreeDropDown = React.memo(
  ({ data, onChange, className }) => {
    return (
      <>
        <DropdownTreeSelect
          data={data}
          onChange={onChange}
          className={className}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);

const CourseConfiguration = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const { data: categories } = useGetCategories();

  const dateRef = React.useRef();

  const [isDynamic, setIsDynamic] = React.useState(props.st.isDynamic);

  const [status, setStatus] = React.useState(props.st.isDisabled ? props.st.status : 1);    //props.st.status ??

  const [expiryDate, setExpiryDate] = React.useState(props.st.expiryDate ?? "2099-12-31T00:00:00");

  const [difficultyLevel, setDifficultyLevel] = React.useState(
    props.st.difficultyLevel ?? 2
  );


  const [duration, setDuration] = React.useState(props.st.duration);
  // const [selectedCategories, setSelectedCategories] = React.useState(
  //   props.st.categories
  // );

  const [categoryTree, setCategoryTree] = React.useState(null);

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      isDynamic,
      difficultyLevel,
      duration: duration == "" ? null : duration,
      type: "configuration",
      status,
      expiryDate,
      // selectedCategories,
    },
  }));
  const sendState = () => {
    return {
      isDynamic,
      difficultyLevel,
      duration: duration == "" ? null : duration,
      type: "configuration",
      status,
      expiryDate,
      // selectedCategories,
    };
  };

  const isValidated = () => {
    return true;
  };

  const createHierarchy = (root) => {
    if (!root) return;

    root.label = root.name;
    root.value = root.id;

    let children = [...categories].map(x => { return { ...x } }).filter(
      (x) => x.parent == root.id && x.isCategory == true
    );

    if (children && children.length > 0) {
      root.children = [];
      let cats = [];

      if (props.st.categories) {
        cats = props.st.categories.split(",");
      }

      children.forEach((x) => {
        x.children = [];
        if (x.isCategory == true) {
          if (cats && cats.length > 0) {
            if (cats.includes(x.id)) {
              x.checked = true;
            } else {
              x.checked = false;
            }
          }

          x.value = x.id;
          x.label = x.name;
          root.children.push(x);
          createHierarchy(x);
        }
      });
    }



    return;
  };

  React.useEffect(() => {
    if (categories) {

      let root = [...categories].map(x => { return { ...x } }).find((x) => x.parent == null);


      if (props.st.categories) {
        const cats = props.st.categories.split(",");
        if (cats.includes(root.id)) {

          root.checked = true;
        }
      }

      createHierarchy(root);
      setCategoryTree(root);
    }
  }, [categories]);

  const onChange = (currentNode, selectedNodes) => {
    setSelectedCategories(selectedNodes.map((x) => x.id).join());
  };

  return (
    <GridContainer justify="center">

      {/* <GridItem xs={12} sm={12} md={5}>
            {categoryTree && (
              <TreeDropDown
                data={categoryTree}
                onChange={onChange}
                className="mdl-demo"
              />
            )}
          </GridItem> */}

      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          name="difficultyLevel"
          options={[
            {
              id: 1,
              name: "Easy",
            },
            {
              id: 2,
              name: "Medium",
            },
            {
              id: 3,
              name: "Difficult",
            },
          ]}
          disabled={props.st.isDisabled}
          setvalue={(v) => setDifficultyLevel(v)}
          value={difficultyLevel}
          label="Difficulty Level"
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={2}>
        <CustomSelect
          name="status"
          options={[
            {
              id: 1,
              name: "Draft",
            },
            {
              id: 2,
              name: "Ready",
            },
            props.st.isDisabled &&
            {
              id: 3,
              name: "Published",
            }
          ].filter(Boolean)}
          disabled={props.st.isDisabled}
          setvalue={(v) => setStatus(v)}
          value={status}
          label="Status"
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={2}>
        <CustomInput
          success={true}
          labelText={<span>Duration <small>(In Day's)</small></span>}
          id="attemptsAllowed"
          formControlProps={{
            fullWidth: true,
          }}
          disabled={props.st.isDisabled}
          inputProps={{
            type: "number",
            onChange: (event) => {
              setDuration(event.target.value);
            },
            value: duration ?? null,
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={3}>
        {/* <FormControlLabel
          style={{ marginTop: '25px' }}
          control={
            <Switch
              value={isDynamic}
              onChange={(e) => {
                setIsDynamic(e.target.checked);
              }}
              checked={isDynamic}
            />
          }
          label={<small>Is Dynamic</small>}
        /> */}
      </GridItem>
      {/* <GridItem xs={12} sm={12} md={3}>
            <CustomInput
              success={true}
              labelText={<span>Expiry Date</span>}
              id="expiryDate"
              formControlProps={{
                fullWidth: true,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                ref: dateRef,
                type: "datetime-local",
                onChange: (event) => {
                  setExpiryDate(event.target.value);
                },
                value: expiryDate,
              }}
            />
          </GridItem> */}

    </GridContainer>
  );
});

CourseConfiguration.displayName = "Step1";

export default CourseConfiguration;
